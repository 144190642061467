let app:any = null; 
let open: Function | null = null;

export async function createDeveloperPrivilegesService(personUserId: number, tpy: String, element: HTMLElement) { 
    var devApp = await import("system.libraries.vue.components.DeveloperPrivilegesService.vue").then(m => m.default); 
    const { SingletonApp } = await import("o365-vue-services"); 
    if (app == null) { 
        app = SingletonApp.getOrCreateApp({ 
            id: "o365-navbar-developerprivilegesService-app", 
            appComponent: devApp, 
            provides: { 
                "set-on-open": (pOpenFn: Function) => { 
                    open = pOpenFn
                },
                "data": {
                    personUserId,
                    "type": tpy
                }
            },
            appendTo: element
        })
        app.mount(); 
        open();    
    } 
    else if (open) {
        open(); 
    }
} 